import { useSnapshot } from 'valtio';
import { analyticsState } from '../state/amplitude';

export const useAnalytics = () => {
  const analytics = useSnapshot(analyticsState);

  if (analytics.amplitude) {
    return analytics.amplitude;
  }
  return null;
};
